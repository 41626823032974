import React from "react";
import styled from "styled-components";

export function DatasetMetadata({ metadata }) {
  return (
    <TableWrapper>
      <table>
        <tbody>
          {metadata.attribution && (
            <tr>
              <td>Attribution</td>
              <td>{metadata.attribution}</td>
            </tr>
          )}
          {metadata.dataUseLimits && (
            <tr>
              <td>Data use limits</td>
              <td>{metadata.dataUseLimits}</td>
            </tr>
          )}
          {metadata.date && (
            <tr>
              <td>Date</td>
              <td>{metadata.date}</td>
            </tr>
          )}
          {metadata.license && (
            <tr>
              <td>License</td>
              <td>{metadata.license}</td>
            </tr>
          )}
          {metadata.originator && (
            <tr>
              <td>Originator</td>
              <td>{metadata.originator}</td>
            </tr>
          )}
          {metadata.provider && (
            <tr>
              <td>Provider</td>
              <td>{metadata.provider}</td>
            </tr>
          )}
        </tbody>
      </table>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  background-color: #1a191a;
  table {
    width: 100%;
  }
  td {
    padding: 10px;
  }
  td:nth-child(1) {
    width: 1px;
    font-weight: bolder;
    padding-right: 15px;
  }
  td:nth-child(2) {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
  }

  table,
  tr,
  td {
    border: 1px solid grey;
  }
  tr td:first-of-type {
  }
`;
