import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { DatasetMetadata } from "../components/DatasetMetadata";
import { Treeview } from "../components/Treeview";
import { Button, LinkButton } from "../style";

export default function DatasetDetails({ pageContext, location }) {
  const {
    datasetLabel,
    description,
    metadata,
    previewImageUrl,
    sampleProperties,
  } = pageContext;

  return (
    <Layout>
      <Page pageTitle={datasetLabel}>
        <h2>{datasetLabel}</h2>
        <Button
          primary
          onClick={() => {
            if (location.state && location.state.navigatedFromDatasetList) {
              navigate(-1); // go back (retains scroll position)
            } else {
              navigate("/data");
            }
          }}
        >
          ← Back to list
        </Button>
        <LinkButton to="/contact" secondary style={{ marginLeft: "10px" }}>
          Request Access
        </LinkButton>

        <p style={{ marginTop: "20px" }}>
          {description || "No description available."}
        </p>
        {previewImageUrl && <img src={previewImageUrl} alt={datasetLabel} />}
        {metadata && (
          <div style={{ marginTop: "20px" }}>
            <h3>Metadata</h3>
            <DatasetMetadata metadata={metadata} />
          </div>
        )}
        {sampleProperties && (
          <div style={{ marginTop: "30px" }}>
            <h3>Sample Properties</h3>
            <TreeviewWrapper>
              <Treeview jsonData={sampleProperties} />
            </TreeviewWrapper>
          </div>
        )}
      </Page>
    </Layout>
  );
}
const TreeviewWrapper = styled.div`
  ul:first-child {
    padding: 10px 10px 15px 15px !important;
    margin-left: 0 !important;
  }
`;
